"use client";

import { useRouter } from "next/navigation";
import Image from "next/image";
import React from "react";

import styles from "./error.module.scss";

function ErrorBoundary({ error }) {
  const router = useRouter();
  return (
    <>
      <main className={styles.main}>
        <aside>
          <Image
            width={100}
            height={100}
            alt="error-image"
            className={styles.errorImage}
            src={"/images/error-image.png"}
          />
          <h1>Oops! Something Went Wrong</h1>
          <button className={styles.button} onClick={() => router.push("/")}>
            Home
          </button>
        </aside>
      </main>
    </>
  );
}

export default ErrorBoundary;
